import { AppTheme } from "../appThemes";
import { ExtractAtomicOperationFromDefinition } from "../atomic-operations/AtomicOperationDefinition";
import { TimezoneName } from "../dateTypes";
import { AppMetadataField, LayoutType } from "../enums";
import { HexVersionId, StatusId } from "../idTypeBrands";
import { KernelSize } from "../kernelConfigTypes";
import { SemanticCap } from "../semanticCaps";
import { QueryCacheTimeout } from "../sql";
import { KernelImage } from "../typeBrands";

import { createHexVersionAtomicOperationDefinition } from "./HexVersionAtomicOperationDefinition";

export interface HexVersionUpdatableFields {
  title: string;
  autoRerun: boolean;
  cacheDefaultAppState: boolean;
  logicQueryCacheTimeout: QueryCacheTimeout | null;
  publishedQueryCacheTimeout: QueryCacheTimeout | null;
  prerunApp: boolean;
  description: string | null;
  layoutType: LayoutType | null;
  statusId: StatusId | null;
  timezone: TimezoneName | null;
  visibleMetadataFields: AppMetadataField[] | readonly AppMetadataField[];
  kernelSize: KernelSize;
  kernelImage: KernelImage;
  graphV3: boolean;
  appTheme: AppTheme;
  gridAppMaxWidth: number | null;
  refreshStalePublishedApp: boolean;
  allowExecutionReordering: boolean;
}

const _UpdateHexVersionSafeFields: {
  [key in keyof HexVersionUpdatableFields]: "";
} = {
  title: "",
  autoRerun: "",
  cacheDefaultAppState: "",
  logicQueryCacheTimeout: "",
  publishedQueryCacheTimeout: "",
  prerunApp: "",
  description: "",
  layoutType: "",
  statusId: "",
  timezone: "",
  visibleMetadataFields: "",
  kernelSize: "",
  kernelImage: "",
  graphV3: "",
  appTheme: "",
  gridAppMaxWidth: "",
  refreshStalePublishedApp: "",
  allowExecutionReordering: "",
};
export const UpdateHexVersionSafeFields = Object.keys(
  _UpdateHexVersionSafeFields,
);

const UPDATE_HEX_VERSION_TYPE = "UPDATE_HEX_VERSION" as const;
export const UPDATE_HEX_VERSION = createHexVersionAtomicOperationDefinition({
  type: UPDATE_HEX_VERSION_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "and",
    and: [
      {
        kind: "hasSemanticCap",
        cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
      },
      {
        kind: "noAuth",
        message:
          "More specific write auth is checked manually in the handler and varies depending on key arg",
      },
    ],
  },
  logSafe: ["hexVersionId", "key"],
  conflictId: (op) =>
    `${UPDATE_HEX_VERSION_TYPE}-${op.payload.hexVersionId}-${op.payload.key}`,
  create: <K extends keyof HexVersionUpdatableFields>(
    hexVersionId: HexVersionId,
    key: K,
    value: HexVersionUpdatableFields[K],
  ) => ({
    type: UPDATE_HEX_VERSION_TYPE,
    payload: {
      hexVersionId,
      key,
      value,
    },
  }),
});

export type UPDATE_HEX_VERSION = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_HEX_VERSION
>;
