import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations";
import { CellId } from "../../idTypeBrands";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

import { CREATE_CELL } from "./createCellOperation";
import { RESTORE_CELL_TYPE } from "./restoreCellOperation";

export const DELETE_CELL_TYPE = "DELETE_CELL" as const;
export const DELETE_CELL = createHexVersionAtomicOperationDefinition({
  type: DELETE_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_PROJECT_CONTENTS,
    idArg: "cellId",
    idType: "Cell",
  },
  logSafe: ["cellId"],
  conflictId: (op) =>
    `${DELETE_CELL_TYPE}-${RESTORE_CELL_TYPE}-${op.payload.cellId}`,
  creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
  excludeFromHistory: (op) => op.payload.excludeFromHistory,
  create: ({
    cellId,
    excludeFromHistory,
    initiatedByParentBlockCell,
    skipToaster,
  }: {
    cellId: CellId;
    initiatedByParentBlockCell?: boolean;
    excludeFromHistory?: boolean;
    skipToaster?: boolean;
  }) => ({
    type: DELETE_CELL_TYPE,
    payload: {
      cellId,
      initiatedByParentBlockCell: initiatedByParentBlockCell ?? false,
      excludeFromHistory: excludeFromHistory ?? false,
      skipToaster: skipToaster ?? false,
    },
  }),
});

export type DELETE_CELL = ExtractAtomicOperationFromDefinition<
  typeof DELETE_CELL
>;
